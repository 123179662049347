import type { NextPage } from "next";
import Head from "next/head";
import styles from "./index.module.scss";
import { StationsList } from "app/components/stations-list/stations-list.component";
import { useGetPillowCategoriesPath } from "services-hooks/hooks";
import { Category } from "services-hooks/types";
import React from "react";
import { Wall } from "app/components/wall/wall.component";
import { PodcastList } from "app/components/podcats-list/podcast-list.component";
import { useGoBackHistory } from "app/hooks/go-back.hook";
import { useTranslate } from "app/hooks/lang";
import useMetaDescription from "app/utils/getMetaDescription";

const Home: NextPage = () => {
  const t = useTranslate();
  const getMetaDescription = useMetaDescription();
  const {
    data,
    error,
    isLoading: stationsLoading,
    fetchNextPage: fetchNextPageStations,
  } = useGetPillowCategoriesPath("radioline_selection", {
    pageSize: 10,
  });

  const {
    data: podcastData,
    error: podcastError,
    isLoading: podcastsLoading,
    fetchNextPage: fetchNextPagePodcasts,
  } = useGetPillowCategoriesPath("podcasts_selection", { pageSize: 10 });

  const radioSelectionList =
    data?.pages.reduce((acc, current) => {
      if (current.data.body?.content) {
        acc = [...acc, ...current.data.body.content];
      }
      return acc;
    }, [] as Category[]) || [];

  const podcastSelectionList =
    podcastData?.pages.reduce((acc, current) => {
      if (current.data.body?.content) {
        acc = [...acc, ...current.data.body.content];
      }
      return acc;
    }, [] as Category[]) || [];
  useGoBackHistory("INDEX");
  return (
    <div className={styles.container}>
      <Head>
        <title>Radioline</title>
        { getMetaDescription(t("homepage-meta-description")) }
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <StationsList
        label={t("tabs.stations")}
        items={radioSelectionList}
        loading={stationsLoading}
        className={styles.home_stations_wrapper}
        fetchNextPage={fetchNextPageStations}
      />
      <PodcastList
        label={t("tabs.podcasts")}
        items={podcastSelectionList}
        loading={podcastsLoading}
        className={styles.home_podcasts_wrapper}
        fetchNextPage={fetchNextPagePodcasts}
      />
      <Wall />
    </div>
  );
};

export default Home;
