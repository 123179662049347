import { useFeaturesContext } from "app/context/features.context";
import { useRouter } from "next/router";

export const useNavigateBack = () => {
  const router = useRouter();
  const { arrowNavigationEnabled } = useFeaturesContext();
  const { back } = router.query;
  const shouldGoBack = back as string;
  const goBack = () => {
    if (shouldGoBack) {
      router.back();
    } else if (arrowNavigationEnabled) {
      try {
        console.log("CLOSE APP");
        
        window.close();
      } catch (e) {}
    } else {
      router.push("/");
    }
  };
  return goBack;
};

export const useGoToRadio = () => {
  const router = useRouter();
  const goToRadio = (permalink: string) => {
    router.push({
      pathname: permalink,
      query: { back: true },
    });
  };
  return goToRadio;
};

export const useGoToPodcast = () => {
  const router = useRouter();
  const goToPodcast = (permalink: string) => {
    router.push({
      pathname: permalink,
      query: { back: true },
    });
  };
  return goToPodcast;
};

export const useNavigatePath = () => {
  const router = useRouter();
  const navigate = (path: string, back: boolean = true) => {
    router.push({
      pathname: path,
      query: { back: back ? true : undefined },
    });
  };
  return navigate;
};
